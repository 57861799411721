
import api from '@/api/co.api'
import app from '@/api/co.app'
import lib from '@/api/co.lib'
import act from '@/api/co.action'
import notice from '@/api/co.notice'

import notPreset from '@/pages/mixins/notice/mix.notice.preset'

// const BASE64 = require('@/api/lib/base64.js')

export default {
  data () {
    return {
      notInfo: {},
      notPrev: {},
      notNext: {},
      sideParts: [],
      navMeta: {},
      notPart: {}
    }
  },
  methods: {
    viewAccessory (ev) {
      // console.log('ev: %o', ev)
      // const enUrl = encodeURIComponent(BASE64.encode(ev.url))
      // const opUrl = `${app.base.office_url}/onlinePreview?url=${enUrl}`
      // window.open(opUrl, '_target')

      api.comm.viewFile(ev)
    },
    toNotice (part, notice) {
      const name = api.page.noticeDetail.name
      const params = {
        name: part.name,
        noticeId: notice.noticeId,
        type: notice.type,
        topicId: notice.topicId,
        categoryId: notice.categoryId,
      }
      lib.cookie.set(name, JSON.stringify(params))
      Object.assign(this.navMeta, params)

      this.setCrumbs(part, false)
      this.configSide([notPreset.Names.NAVIGATE, part.name])

      this.getNotice({
        noticeId: notice.noticeId
      })
      this.getSibing({
        noticeId: notice.noticeId
      })
    },
    toNotices (ev, category) {
      act.navigate({
        that: this,
        pagename: api.page.noticeList.name,
        item: {
          type: 'notices'
        },
        category
      })
    },
    initSideParams (obj) {
      const executed = function (res) {
        if (res.status) {
        }
      }

      obj.params = notice.getParams({
        siteId: app.init.siteId,
        type: obj.type,
        topicId: obj.topicId,
        categoryId: obj.categoryId,
        status: 'publish',
        limit: '3',
        caches: obj.items,
        executed
      })
    },
    loadSideNotices (reload = true) {
      for (const key in this.sideParts) {
        const item = this.sideParts[key]
        item.params.reload = reload
        api.http.getItems(item.params)
      }
    },
    configSide (exclude = []) {
      this.sideParts = []
      const parts = notPreset.Parts
      for (const key in parts) {
        const part = parts[key]
        if (exclude.indexOf(part.name) === -1) {
          const dst = api.comm.deepClone(part)
          this.initSideParams(dst)
          this.sideParts.push(dst)
        }
      }
      this.loadSideNotices(true)
    },
    getSibing ({
      noticeId
    }) {
      const me = this
      const executing = function () {

      }
      const executed = function (res) {
        // console.log('mix.party.notice getSibing res: %o', res)
        me.notNext = {}
        me.notPrev = {}
        if (res.status) {
          me.notNext = res.data.next || {}
          me.notPrev = res.data.previous || {}
        }
      }

      notice.sibling({
        noticeId,
        siteId: app.init.siteId,
        type: this.navMeta.type,
        status: 'publish',
        topicId: this.navMeta.topicId,
        categoryId: this.navMeta.categoryId,
        executing,
        executed
      })
    },
    getNotice ({
      noticeId
    }) {
      const me = this
      const executing = function () {

      }
      const executed = function (res) {
        if (res.status) {
          me.notInfo = res.data
        }
      }

      notice.getDetail({
        id: noticeId,
        executing,
        executed
      })
    }
  }
}
