<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="notice_detail"
      space="40"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="8"
          >
            <div
              class="d-flex flex-column"
            >
              <div class="d-flex flex-column justify-center align-center">
                <span class="text-lg-h5 primary--text">
                  {{ notInfo.title }}
                </span>
                <div class="d-flex flex-row justify-space-between align-center mt-3">
                  <span class="text-body-1 blue-grey--text lighten-3">
                    发布时间:
                    <span class="pl-1 blue-grey--text darken-4">{{ notInfo.createTime }}</span>
                  </span>

                  <span class="text-body-1 blue-grey--text lighten-3 ml-6">
                    阅读次数:
                    <span class="pl-1 blue-grey--text darken-4">{{ notInfo.hits }}</span>
                  </span>
                </div>
                <div class="co-w-full co-h2 primary my-4" />
                <div class="d-flex flex-column co-w-full">
                  <v-row>
                    <v-col cols="12">
                      <p
                        v-if="notInfo.model === 'article'"
                        v-html="notInfo.content"
                      />
                      <div
                        v-else-if="notInfo.model === 'shop'"
                        class="d-flex flex-column"
                      >
                        <v-img
                          v-for="(img, imgIndex) in notInfo.imageList"
                          :key="`img_${imgIndex}`"
                          :src="img.value"
                          contain
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-if="notInfo.accessory && notInfo.accessory.length"
                  class="d-flex flex-column co-w-full"
                >
                  <land-navigation
                    title="附件"
                    size="text-lg-h6"
                  />
                  <div class="d-flex flex-column">
                    <div
                      v-for="(acc, accIndex) in notInfo.accessory"
                      :key="`acc_${accIndex}`"
                      class="d-flex flex-row mt-4 blue-grey lighten-5 px-5 py-3 rounded"
                    >
                      <v-hover
                        v-slot="{ hover }"
                        close-delay="120"
                      >
                        <a
                          :class="['text-subtitle-1', 'd-inline-block', 'text-truncate',
                                   hover ? 'primary--text' : 'black--text darken-4'
                          ]"
                          style="flex: 1;"
                          @click="viewAccessory(acc)"
                        >
                          {{ acc.name }}
                        </a>
                      </v-hover>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="d-flex flex-column"
              >
                <div
                  v-if="notPrev.noticeId"
                  class="d-flex flex-row mt-4"
                >
                  <span class="co-shrink0 text-subtitle-1 grey--text lighten-3 pr-4">上一篇:</span>
                  <a
                    class="text-subtitle-1"
                    @click="toNotice(notPart, notPrev)"
                  >
                    {{ notPrev.title }}
                  </a>
                </div>
                <div
                  v-if="notNext.noticeId"
                  class="d-flex flex-row mt-4"
                >
                  <span class="co-shrink0 text-subtitle-1 grey--text lighten-3 pr-4">下一篇:</span>
                  <a
                    class="text-subtitle-1"
                    @click="toNotice(notPart, notNext)"
                  >
                    {{ notNext.title }}
                  </a>
                </div>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="hidden-sm-and-down"
          >
            <div
              v-for="(part, partIndex) in sideParts"
              :key="`part_${partIndex}`"
              class="d-flex flex-column ml-8"
            >
              <land-navigation
                :title="part.title"
                :items="part.buttons"
                size="text-lg-h6"
                :margin="`${partIndex > 0 ? 'mb-2 mt-6' : 'mb-2'}`"
                @click="toNotices($event, part)"
              />
              <land-plain-notices
                :items="part.items"
                dense
                short-dated
                back-color="white"
                card-color="white"
                padding="py-2"
                @click="changeNotice($event, part)"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import lib from '@/api/co.lib'

  import mixNoticeBanner from '@/pages/mixins/notice/mix.notice.banner'
  import mixNoticeDetail from '@/pages/mixins/notice/mix.notice.detail'

  import noticePreset from '@/pages/mixins/notice/mix.notice.preset'

  export default {
    metaInfo () {
      return {
        title: this.pageTitle
      }
    },
    mixins: [
      mixNoticeBanner,
      mixNoticeDetail
    ],
    data () {
      return {
        pageTitle: '资讯详情'
      }
    },
    created () {
      const name = api.page.noticeDetail.name
      const nav = JSON.parse(lib.cookie.get(name))
      Object.assign(this.navMeta, nav)

      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      const part = noticePreset.Parts[nav.name] || {}
      this.pageTitle = part.title

      Object.assign(this.notPart, part)

      this.setCrumbs(part, false)
      this.configSide([noticePreset.Names.NAVIGATE, nav.name])

      this.getNotice({
        noticeId: nav.noticeId
      })
      this.getSibing({
        noticeId: nav.noticeId
      })
    },
    methods: {
      changeNotice (ev, part) {
        this.toNotice(part, ev.item)
      }
    }
  }
</script>
